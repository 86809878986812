import Design1 from './design-1';
import Design2 from './design-2';
import Design3 from './design-3';
import Design4 from './design-4';

import './index.css';
import useApplyUserDefinedListOverrides from "../../hooks/use-user-defined-list-sort";
import {WebsiteContext} from "../../index";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {makeItems} from "../../utils/utils";

export default function ListOfItemsBlock(props) {
    const context = useContext(WebsiteContext);
    const [items, setItems] = useState(props?.values?.items || []);

    const values = useApplyUserDefinedListOverrides({
        ...props,
        values: {
            ...props?.values,
            items: props?.values?.source === 'collection' ? items : props.values.items
        }
    })

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        if (context.builder && props?.values?.source === 'collection') {
            axios
                .get(`/api/v2/channels/${context.channel}/nodes/${props.values.schema}/`, {cancelToken: cancelToken.token})
                .then(({data}) => {
                    const children = data?.[0]?.children;
                    setItems(makeItems(children, props?.values?.item));
                })
                .catch(err => {
                    if (!axios.isCancel(err)) console.log(err);
                })
        }
        return () => cancelToken.cancel();
    }, [props?.values]);

    switch (props.values?.design) {
        case 0 :
            return (<Design1 {...props} values={values}/>);
        case 1:
            return (<Design2 {...props} values={values}/>);
        case 2:
            return (<Design3 {...props} values={values}/>);
        case 3:
            return (<Design4 {...props} values={values}/>);
        default:
            return (<Design1 {...props} values={values}/>);
    }
}