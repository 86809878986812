// Note: Use `require` statements as this file will be used in a CommonJS environment
const _mergeWith = require("lodash/mergeWith");
const _isArray = require("lodash/isArray");
const _isObject = require("lodash/isObject");
const _get = require("lodash/get");
const _set = require("lodash/set");
const _unset = require("lodash/unset");
const _forEach = require('lodash/forEach')
const _isEmpty = require('lodash/isEmpty')
const _has = require('lodash/has')

/**
 * Formats the provided number of kilobytes into a more human-readable string.
 *
 * @param {number} kilobytes The number kilobytes to format.
 * @return {string} The formatted string.
 */
const formatBytes = function formatBytes(kilobytes) {
    if (kilobytes === 0 || kilobytes === null || kilobytes === undefined || isNaN(kilobytes) || kilobytes === '') {
        return '0 KB';
    }

    const bytes = kilobytes / 1000
    const sizes = ['KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
}

/**
 * Returns the correct file extension for the provided mime type.
 *
 * @param {string} mimeType The mime type to read and return it's corresponding file extension.
 * @return {string} The file extension for the provided mime type.
 */
const mimeTypeToExtension = function mimeTypeToExtension(mimeType) {
    const [_, subtype] = mimeType.split('/');

    const specialCases = {
        'jpeg': 'jpg',
        'svg+xml': 'svg',
        'vnd.apple.keynote': 'keynote',
        'vnd.apple.numbers': 'numbers',
        'vnd.apple.pages': 'pages',
        'quicktime': 'mov'
    };

    if (specialCases[subtype]) {
        return '.' + specialCases[subtype];
    }

    return '.' + subtype;
}

/**
 * Merges the provided sources from left to right.
 * Handles merging arrays of objects with an id property.
 *
 * @param sources One or more sources to merge.
 * @return {object}
 */
const deepMerge = function deepMerge(...sources) {
    return _mergeWith({}, ...sources, function reducer(targetValue, sourceValue) {
        if (_isArray(targetValue) && _isArray(sourceValue)) {
            // Only add entries that don't exist keeping the array reference of the target in tacked
            sourceValue.forEach((source, index) => {
                const target = targetValue.find(target => {
                    if (_isObject(source) && _isObject(target)) {
                        return source.id === target.id;
                    } else {
                        return source === target;
                    }
                });

                // If it doesn't exist, then just add it
                if (!target) {
                    targetValue.push(source);
                }
                // Handle when working with objects
                else if (_isObject(source) && _isObject(target)) {
                    _mergeWith(target, source, reducer);
                }
                // Handle when working with primitives
                else {
                    const targetIndex = targetValue.indexOf(target);
                    targetValue.splice(targetIndex, 1);
                    targetValue.splice(index, 0, source);
                }
            });

            return targetValue;
        }

        if (_isObject(targetValue) && _isObject(sourceValue)) {
            return _mergeWith({}, targetValue, sourceValue, reducer);
        }

        return sourceValue !== null && sourceValue !== undefined && sourceValue !== '' ? sourceValue : targetValue;
    });
}

/**
 * Clears the value of a field from the provided context
 *
 * When `keepInSync` is false:
 *
 * If all fields from an object in an array are removed, then the object which contained the field will
 * also be removed.
 *
 * If the object removed from the above array was the last item, then the property that houses the array reference
 * will be removed.
 *
 * @param {object} context The context to clear the field from.
 * @param {string} fieldPath The path of the field to clear (supports nested paths).
 * @param {boolean} [keepInSync] True when the block data is inherited and should be kept in sync with a template.
 *                               This will set the fields to empty string rather than removing the fields so that we
 *                               override the inherited data.
 * @param {string|number|boolean|null} [overridingValue] The value to set when clearing a field and the context is kept
 *                                                     in sync with a template.
 *                                                     Default is empty string.
 */
const clearField = function (context, fieldPath, keepInSync = false, overridingValue = '') {
    if (keepInSync) {
        // Only set the property to the value if the property path exists
        if (_has(context, fieldPath)) {
            _set(context, fieldPath, overridingValue)
        }
    } else {
        _unset(context, fieldPath)

        let parentFieldPath = fieldPath.substring(0, fieldPath.lastIndexOf('.'))

        // Ignore if there is no parent path to process
        if (!parentFieldPath) return

        let parentContext = _get(context, parentFieldPath)

        // Ignore if the parent is not an empty object
        if (!(!_isArray(parentContext) && _isObject(parentContext) && _isEmpty(parentContext))) return

        // Remove the object the field was on if it is empty
        _unset(context, parentFieldPath)

        parentFieldPath = fieldPath.substring(0, parentFieldPath.lastIndexOf('.'))
        parentContext = _get(context, parentFieldPath)

        // Clear any empty items from the array
        if (_isArray(parentContext)) parentContext = parentContext.filter(Boolean)

        // Ignore if the parent is not an empty array
        if (_isArray(parentContext) && !parentContext.length
            || _isObject(parentContext) && _isEmpty(parentContext)) {

            // Remove the object the field was on if it is empty
            _unset(context, parentFieldPath)
        }
    }
}

/**
 * Removes fields from the products block that reference lookup data.
 *
 * @param block The block to clear references from.
 * @param {boolean} [keepInSync] True when the block data is inherited and should be kept in sync with a template.
 *                               This will set the fields to empty string rather than removing the fields so that we
 *                               override the inherited data.
 * @param {object} syncedBlock The synced block to compare the block to.
 */
const clearCategoryReferencesFromBlock = function (block, keepInSync = false, syncedBlock) {
    if (!_isObject(block)) return;
    if (block.type !== 'products-block' && block.type !== 'product-search-block') return;

    if (syncedBlock) {
        if (syncedBlock.values?.catalogueId === block.values?.catalogueId) clearField(block, 'values.catalogueId', keepInSync);
        if (syncedBlock.values?.categoryUuid === block.values?.categoryUuid) clearField(block, 'values.categoryUuid', keepInSync);
        if (syncedBlock.values?.nodeCategoryId === block.values?.nodeCategoryId) clearField(block, 'values.nodeCategoryId', keepInSync);
        if (syncedBlock.values?.categoryId === block.values?.categoryId) clearField(block, 'values.categoryId', keepInSync);
    } else {
        clearField(block, 'values.catalogueId', keepInSync);
        clearField(block, 'values.categoryUuid', keepInSync);
        clearField(block, 'values.nodeCategoryId', keepInSync);
        clearField(block, 'values.categoryId', keepInSync);
    }

    if (_isArray(block.values.filters)) {
        block.values.filters = block.values.filters.reduce((result, filter) => {
            if (syncedBlock) {
                const syncedFilter = syncedBlock?.values?.filters?.find(f => f.id === filter.id);
                if (!syncedFilter) result.push(filter);
            }
            return result
        }, []);
    }

}

/**
 * Removes fields from the form block that reference lookup data.
 *
 * @param {object} block The block to clear references from.
 * @param {boolean} [keepInSync] True when the block data is inherited and should be kept in sync with a template.
 *                               This will set the fields to empty string rather than removing the fields so that we
 *                               override the inherited data.
 *                                * @param syncedBlock The synced block to compare the block to
 * @param {object} syncedBlock The synced block to compare the block to.
 */
const clearFormReferencesFromBlock = function (block, keepInSync = false, syncedBlock) {
    if (!_isObject(block)) return;
    if (block.type !== 'form-block') return;

    syncedBlock
        ? syncedBlock.values?.form?.uuid === block.values?.form?.uuid
            ? clearField(block, 'values.form.uuid', keepInSync)
            : null
        : clearField(block, 'values.form.uuid', keepInSync)
}

/**
 * Removes fields related to a block action.
 *
 * @param {object} block The block to clear references from.
 * @param {boolean} [keepInSync] True when the block data is inherited and should be kept in sync with a template.
 *                               This will set the fields to empty string rather than removing the fields so that we
 *                               override the inherited data.
 * @param {object} syncedBlock The synced block to compare the block to.
 */
const clearActionReferencesFromBlock = function (block, keepInSync = false, syncedBlock) {
    if (!_isObject(block)) return;

    _forEach(block, (value, key) => {
        // Remove action references when the value is an object and the property name is `action`
        if (key === 'action') {
            if (syncedBlock?.[key]) {
                if (value.uniqueUri === syncedBlock[key].uniqueUri) clearField(value, 'uniqueUri', keepInSync)
                if (value.productUuid === syncedBlock[key].productUuid) clearField(value, 'productUuid', keepInSync)
                return
            } else {
                clearField(value, 'uniqueUri', keepInSync)
                clearField(value, 'productUuid', keepInSync)
                return
            }
        }
        // Recurse arrays
        if (_isArray(value)) {
            return value.forEach((value) => {
                const syncBlockvalue = syncedBlock?.[key]?.find(v => v?.id === value?.id);
                clearActionReferencesFromBlock(value, keepInSync, syncBlockvalue)
            })
        }

        // Recurse objects
        if (!_isArray(value) && _isObject(value)) {
            return clearActionReferencesFromBlock(value, keepInSync, syncedBlock?.[key])
        }
    })
}

/**
 * Removes all references to from all blocks that reference lookup data.
 *
 * @param {object} blocks The list of blocks to process.
 * @param {boolean} [keepInSync] True when the block data is inherited and should be kept in sync with a template.
 *                               This will set the fields to empty string rather than removing the fields so that we
 *                               override the inherited data.
 * @param syncedNodeBlocks The synced pages list of blocks to compare to.
 */
const clearAllReferencesFromBlocks = function (blocks, keepInSync = false, syncedNodeBlocks) {
    if (!_isArray(blocks)) return

    blocks.forEach((block) => {
        const syncedBlock = syncedNodeBlocks?.find(b => b.id === block.id);
        clearActionReferencesFromBlock(block, keepInSync, syncedBlock)
        clearFormReferencesFromBlock(block, keepInSync, syncedBlock)
        clearCategoryReferencesFromBlock(block, keepInSync, syncedBlock)
    })
}

/**
 * Filters items by `startsOn` and `expiresOn` dates, considering UTC time.
 *
 * Rules:
 * - Include if `startsOn` is past/today (UTC) or `expiresOn` is future (UTC).
 * - Include if neither `startsOn` nor `expiresOn` exists.
 *
 * @param {Array} items - Array of items to filter.
 * @returns {Array} Filtered items.
 */
const liveContentFilter = (items) => {
    if (!items) return [];
    const now = new Date().getTime();

    return items?.filter(item => {
        const startsOn = item.startsOn ? new Date(item.startsOn).getTime() : null;
        const expiresOn = item.expiresOn ? new Date(item.expiresOn).getTime() : null;

        return (
            (startsOn && startsOn <= now && (!expiresOn || expiresOn > now)) || // Valid `startsOn` with optional `expiresOn`
            (!startsOn && expiresOn && expiresOn > now) ||                     // Valid `expiresOn` without `startsOn`
            (!startsOn && !expiresOn)                                          // Neither exists
        );
    });
}

const makeItems = (children, fields) => {
    if (children?.length <= 0) return [];
    const items = liveContentFilter(children)?.map(child => {
        return {
            title: _get(child, fields.title, ""),
            text: _get(child, fields.text, ""),
            image: _get(child, fields.image, ""),
            altText: _get(child, fields.altText, ""),
            showingActionButton: !!_get(child, fields.action?.label, null),
            dataPosition: child.dataPosition,
            action: {
                label: _get(child, fields.action?.label, ""),
                type: _get(child, fields.action?.type, ""),
                uniqueUri: _get(child, fields.action?.uniqueUri, ""),
                value: _get(child, fields.action?.value, ""),
                target: _get(child, fields.action?.target, "_self"),
            },
            detailsPopup: {
                label: _get(child, fields.detailsPopup?.label, ""),
                text: _get(child, fields.detailsPopup?.text, ""),
            }
        }
    })
    return items.sort((a, b) => a.dataPosition - b.dataPosition);
}

module.exports = {
    formatBytes,
    mimeTypeToExtension,
    deepMerge,
    clearField,
    clearActionReferencesFromBlock,
    clearFormReferencesFromBlock,
    clearCategoryReferencesFromBlock,
    clearAllReferencesFromBlocks,
    liveContentFilter,
    makeItems
}